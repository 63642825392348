import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const TabBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabs, setTabs] = useState([
    { name: "Overview", href: "/planner/updater/overview", current: true },
    { name: "Updates", href: "/planner/updater/updates", current: false },
    { name: "Action History", href: "/planner/updater/action-history", current: false },
    { name: "Packages & Activities", href: "/planner/updater/packages", current: false },
  ]);
  // const tabs = ["Overview", "Updates", "Action History", "Packages & Activities"];
  // const links = [
  //   "/planner/updater/overview",
  //   "/planner/updater/updates",
  //   "/planner/updater/action-history",
  //   "/planner/updater/packages",
  // ];

  useEffect(() => {
    if (location.pathname === "/planner/updater/overview") {
      tabClicked(0);
    } else if (
      location.pathname === "/planner/updater/updates" ||
      location.pathname.includes("/planner/updater/updates")
    ) {
      tabClicked(1);
    } else if (location.pathname === "/planner/updater/action-history") {
      tabClicked(2);
    } else if (location.pathname === "/planner/updater/packages") {
      tabClicked(3);
    }
  }, [location.pathname]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const tabClicked = (index) => {
    let temp = [...tabs];
    console.log("🚀 ~ tabClicked ~ temp:", temp);
    for (let i = 0; i < temp.length; i++) {
      if (i === index) {
        temp[i].current = true;
      } else {
        temp[i].current = false;
      }
    }
    setTabs(temp);
  };

  return (
    <div className="border-b border-gray-200 pb-5 sm:pb-0">
      <div className="mt-3 sm:mt-4">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            defaultValue={tabs.find((tab) => tab.current).name}
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          >
            {tabs.map((tab, index) => (
              <option
                key={tab.name}
                onClick={() => {
                  navigate(tab.href);
                  tabClicked(index);
                }}
                className="cursor-pointer"
              >
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab, index) => (
              <div
                onClick={() => {
                  navigate(tab.href);
                  tabClicked(index);
                }}
                key={tab.name}
                href={tab.href}
                aria-current={tab.current ? "page" : undefined}
                className={classNames(
                  tab.current
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium cursor-pointer"
                )}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>

    // <div className="bg-[#E5E7F4] w-full h-[48px]">
    //   <div className="flex">
    //     {tabs.map((tab, index) => (
    //       <div
    //         key={tab}
    //         className={`px-4 h-[48px] cursor-pointer flex items-center justify-center ${
    //           tabIndex === index ? "bg-white text-black" : "text-gray-600"
    //         }`}
    //         onClick={() => {
    //           tabClicked(index);
    //           navigate(links[index]);
    //         }}
    //       >
    //         <p className="font-medium text-[16px] text-[#606060] ">{tab}</p>
    //       </div>
    //     ))}
    //   </div>
    // </div>
  );
};

export default TabBar;
