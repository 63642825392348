import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

import AuthLayout from "src/layout/AuthLayout";
import MainLayout from "src/layout/MainLayout";
import Stakeholders from "src/pages/planner/stakeholders/Stakeholders";
import UpdateDetail from "src/pages/planner/updater/updates/UpdateDetail";
import Login from "src/pages/auth/Login";
import ResetPassword from "./pages/auth/ResetPassword";
import Account from "./pages/auth/Account";
import ConnectMSProject from "./pages/auth/ConnectMSProject";
import Welcome from "./pages/auth/Welcome";
import { useEffect } from "react";
import InviteAccpet from "./pages/auth/ValidateInviteToken";
import ValidateInviteToken from "./pages/auth/ValidateInviteToken";
import Home from "./pages/Home";
import Update from "./pages/stakeholder/update/update";
import SetPassword from "./pages/auth/SetPassword";
import Overview from "./pages/planner/updater/Overview/Overview";
import Updates from "./pages/planner/updater/updates/Updates";
import Packages from "./pages/planner/updater/package/Packages";
import UpdaterLayout from "./layout/UpdaterLayout";
import ActionHistory from "./pages/planner/updater/actionhistory/ActionHistory";

function App() {
  const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated);

  const PrivateRoute = ({ auth: isAuthenticated, children }) => {
    return isAuthenticated ? children : <Navigate to="/auth/login" />;
  };

  return (
    <div>
      <ToastContainer />
      <Router>
        <Routes>
          {/* Routes with the top bar */}
          <Route
            element={
              <PrivateRoute auth={isAuthenticated}>
                <MainLayout />
              </PrivateRoute>
            }
          >
            <Route element={<UpdaterLayout />}>
              <Route path="/planner/updater/overview" element={<Overview />} />
              <Route path="/planner/updater/updates" element={<Updates />} />
              <Route path="/planner/updater/action-history" element={<ActionHistory />} />
              <Route path="/planner/updater/packages" element={<Packages />} />
              <Route path="/planner/updater/updates/:id" element={<UpdateDetail />} />
            </Route>

            <Route path="/planner/stakeholders" element={<Stakeholders />} />

            <Route path="/stakeholder/updates" element={<Update />} />
          </Route>

          {/* Routes without the top bar */}
          <Route element={<AuthLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/auth/login" element={<Login />} />
            <Route path="/invite" element={<SetPassword />} />
            <Route
              path="/auth/reset-password"
              element={
                <PrivateRoute auth={isAuthenticated}>
                  <ResetPassword />
                </PrivateRoute>
              }
            />
            <Route
              path="/auth/create-account"
              element={
                <PrivateRoute auth={isAuthenticated}>
                  <Account />
                </PrivateRoute>
              }
            />
            <Route
              path="/auth/connect-msproject"
              element={
                <PrivateRoute auth={isAuthenticated}>
                  <ConnectMSProject />
                </PrivateRoute>
              }
            />
            <Route
              path="/auth/welcome"
              element={
                <PrivateRoute auth={isAuthenticated}>
                  <Welcome />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
