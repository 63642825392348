import React, { useEffect, useState, forwardRef } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import BlueButton from "src/components/buttons/BlueButton";
import WhiteButton from "src/components/buttons/WhiteButton";
import CodeDropDown from "src/components/dropdown/CodeDropDown";
import EmailInput from "src/components/inputs/EmailInput";
import CodeService from "src/services/code.service";
import InviteService from "src/services/invite.service";
import UserService from "src/services/user.service";
import { toast } from "react-toastify";
import DateInput from "src/components/inputs/DateInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import TaskService from "src/services/task.service";
import UpdateService from "src/services/update.service";
import { formatDate } from "src/utils/common";
import { IoMdClose } from "react-icons/io";

const MessageModal = ({ shown, onCancel, task }) => {
  return (
    shown && (
      <div
        className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-30 flex items-center justify-center"
        style={{ zIndex: 1000 }}
      >
        <OutsideClickHandler onOutsideClick={onCancel}>
          <div className="w-[479px]  bg-white rounded-[6px] flex flex-col items-center pb-3">
            <div className="w-full h-[48px] bg-[#e5e7f4] flex items-center justify-center rounded-t-[6px] px-2 relative">
              <p className="font-medium text-[16px] text-center">{task && task.name}</p>
              <div onClick={onCancel} className="absolute right-3 cursor-pointer">
                <IoMdClose />
              </div>
            </div>
            <div className="w-full px-2 ">
              <p className="font-medium text-[12px] text-[#333333] pb-2 mt-3">FINISH</p>

              <div className="w-full h-[32px] border-[#CED0DC] border-[2px] rounded-[5px] flex items-center px-[15px] bg-white shadow-sm">
                <span className="text-[11px] font-bold">{task && formatDate(task.endDate)}</span>
                <span className="text-[11px] font-medium mb-1">&nbsp;&nbsp;→&nbsp;&nbsp;</span>
                <span className="text-[11px] font-medium">{task && formatDate(task.updates[0].updatedEndDate)}</span>
              </div>

              <p className="font-medium text-[12px] text-[#333333] pb-2 mt-3">CATEGORY</p>
              <div className="w-full h-[32px] border-[#CED0DC] border-[2px] rounded-[5px] flex items-center px-[15px] bg-white shadow-sm">
                <p className="text-[11px]  font-medium w-full">{task && task.updates[0].delayCategory.name}</p>
              </div>

              <p className="font-medium text-[12px] text-[#333333] pb-2 mt-3">EXPLANATION</p>
              <div className="w-full min-h-[32px] border-[#CED0DC] border-[2px] rounded-[5px] flex items-center px-[15px] bg-white shadow-sm">
                <p className="text-[11px] font-medium w-full word-break-break-word">
                  {task && task.updates[0].delayReason}
                </p>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default MessageModal;
