import React, { useEffect, useState, forwardRef } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import BlueButton from "src/components/buttons/BlueButton";
import WhiteButton from "src/components/buttons/WhiteButton";
import CodeDropDown from "src/components/dropdown/CodeDropDown";
import EmailInput from "src/components/inputs/EmailInput";
import CodeService from "src/services/code.service";
import InviteService from "src/services/invite.service";
import UserService from "src/services/user.service";
import { toast } from "react-toastify";
import DateInput from "src/components/inputs/DateInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import TaskService from "src/services/task.service";
import UpdateService from "src/services/update.service";
import { formatDate } from "src/utils/common";

const ConfirmationModal = ({ shown, onCancel, submit, impact }) => {
  useEffect(() => {}, []);

  return (
    shown && (
      <div
        className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-30 flex items-center justify-center"
        style={{ zIndex: 1000 }}
      >
        <OutsideClickHandler onOutsideClick={onCancel}>
          <div className="w-[479px]  bg-white rounded-[6px] flex flex-col items-center pb-3">
            <div className="w-full h-[48px] bg-[#e5e7f4] flex items-center justify-center rounded-t-[6px]">
              <span className="font-ChivoMonoMedium text-[20px]">TOTAL SLACK IMPACT</span>
            </div>
            <div className="relative">
              <span className="font-medium text-[40px] text-red-400">{impact}</span>
              <span className="font-normal text-[16px] absolute right-[-40px] top-[25px]">days</span>
            </div>

            <span className="font-ChivoMonoMedium text-[16px]">Do you still want to submit?</span>
            <div className="w-full h-[32px] flex items-center justify-between gap-[12px] px-2  mt-7 ">
              <WhiteButton title="CANCEL" onClick={onCancel} />
              <BlueButton title="SUBMIT" onClick={submit} />
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default ConfirmationModal;
