import { isVisible } from "@testing-library/user-event/dist/utils";
import React, { useState, useEffect } from "react";
import { getImageUrl } from "src/utils/common";

function PasswordInput({ onPasswordChanged }) {
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);

  useEffect(() => {}, []);

  const passwordChanged = (e) => {
    onPasswordChanged(e.target.value);
    setPassword(e.target.value);
  };

  return (
    <div className="relative w-full h-[32px] border-[#CED0DC] border-[2px] rounded-[5px] flex items-center justify-center px-[15px] bg-white shadow-sm">
      <input
        value={password}
        type={visible ? "text" : "password"}
        onChange={passwordChanged}
        className="w-full text-inputEmailTitle text-[11px] font-[400] font-medium"
        placeholder="Enter password"
      />
      <img
        onClick={() => setVisible(!visible)}
        src={getImageUrl("/images/auth/show.svg")}
        className="w-[24px] h-[24px] absolute right-2 cursor-pointer"
      />
    </div>
  );
}

export default PasswordInput;
