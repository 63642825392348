import { useEffect, useState } from "react";

const ActivityStatus = ({ data }) => {
  const [percentageArray, setPercentageArray] = useState([]);

  useEffect(() => {
    let temp = [];
    for (let i = 0; i <= 100; i += 5) {
      temp.push(i);
    }
    setPercentageArray(temp);
  }, []);

  return (
    <div>
      <h3 className="text-base font-semibold leading-6 text-gray-900 overflow-visible">Activity Status</h3>
      <div className="w-full h-[140px] relative mt-3 bg-white ring-1 ring-black ring-opacity-5 rounded-lg overflow-hidden">
        <div className="w-full h-[70px] flex items-center z-30 px-4 absolute top-7">
          <div className="flex items-center justify-center h-full bg-[#1B9CFC]" style={{ width: `${data.completed}%` }}>
            <h3 className="font-semibold text-[16px] text-white">Completed</h3>
          </div>
          <div
            className="flex items-center justify-center h-full bg-[#FEC142]"
            style={{ width: `${data.inProgress}%` }}
          >
            <h3 className="font-semibold text-[16px] text-white">In Progress</h3>
          </div>
          <div
            className="flex items-center justify-center h-full bg-[#FD3E6B]"
            style={{ width: `${data.notStarted}%` }}
          >
            <h3 className="font-semibold text-[16px] text-white">Not Started</h3>
          </div>
        </div>
        <div className="w-full h-full absolute pt-1 pb-6 flex justify-between px-4">
          {percentageArray.map((percent, index) => (
            <div key={index} className="flex flex-col items-center h-full gap-2 relative">
              <div className="w-[1px] flex-auto bg-gray-300" />
              <span className="font-normal text-[12px] absolute bottom-[-18px]">{percent}%</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActivityStatus;
