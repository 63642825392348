import { useEffect, useState } from "react";

import PuffLoader from "react-spinners/PuffLoader";
import InviteService from "src/services/invite.service";
import TaskService from "src/services/task.service";
import UserService from "src/services/user.service";
import ActionHistoryTable from "./ActionHistoryTable";
import ActivityStatus from "./ActivityStatus";
import DelayByCycle from "./DelayByCycle";
import UpdatesPanel from "./UpdatesPanel";
import BarChart from "./components/BarChart";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#007DE5",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Overview = () => {
  const user = UserService.getCurrentUser();
  const [loading, setLoading] = useState(false);
  const [invites, setInvites] = useState([]);
  const [updatePanelData, setUpdatePanelData] = useState({});
  //stakeholder dropdown
  const [stakeholderArray, setStakeholderArray] = useState([]);
  //progress chart
  const [progressChartData, setProgressChartData] = useState({
    notStarted: 0,
    inProgress: 0,
    completed: 0,
  });

  //activity logs
  const [activityLogs, setActivityLogs] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    const runAsyncFunc = async () => {
      setLoading(true);
      await getAllStakeholders();
      getAllTasks();
    };
    runAsyncFunc();
  }, []);

  // useEffect(() => {
  //   UpdateService.getAcceptedAll(user.project.id)
  //     .then((response) => {
  //       // delayBarChat(response.data);
  //       getActivityLogs(response.data);
  //     })
  //     .catch((error) => {
  //       console.log("get accepted invite error: ", error);
  //     });
  //   setSelectedTask(null);
  //   if (selectedStakeholder) {
  //     TaskService.getAllTasksByPackage(selectedStakeholder.code, user.project.id)
  //       .then((response) => {
  //         setTasks(response.data);
  //       })
  //       .catch((error) => {
  //         console.log("get accepted invite error: ", error);
  //       });

  //     UpdateService.getActivityLogsForPackage(selectedStakeholder.code, user.project.id)
  //       .then((response) => {
  //         setActivityLogs(response.data);
  //         console.log("🚀 ~ useEffect ~ response:", response);
  //       })
  //       .catch((error) => {
  //         console.log("get accepted invite error: ", error);
  //       });
  //   }
  // }, [selectedStakeholder]);

  // useEffect(() => {
  //   setSelectedTask(tasks[0]);
  // }, [tasks]);

  // useEffect(() => {
  //   if (selectedTask) {
  //     setActivityLogs([]);
  //     UpdateService.getActivityLogsForTask(selectedTask.id)
  //       .then((response) => {
  //         setActivityLogs(response.data);
  //         console.log("🚀 ~ useEffect ~ response:", response);
  //       })
  //       .catch((error) => {
  //         console.log("get accepted invite error: ", error);
  //       });
  //   }
  // }, [selectedTask]);

  // const getActivityLogs = (updatesData) => {
  //   let updates = [...updatesData];
  //   if (selectedStakeholder.code !== "All") {
  //     updates = updatesData.filter((update) => update.code === selectedStakeholder.code);
  //   }

  //   let tempLogs = [];
  //   for (let update of updates) {
  //     let updateString = "";
  //     if (update.updatedStartDate) {
  //       updateString += `START DATE ${formatDate(update.startDate)} → ${formatDate(update.updatedStartDate)}`;
  //     }

  //     if (update.updatedEndDate) {
  //       if (updateString.length > 0) updateString += ", ";
  //       updateString += `END DATE ${formatDate(update.endDate)} → ${formatDate(update.updatedEndDate)}`;
  //       updateString += `, CATEGORY: ${update.delayCategory.name}, EXPLANATION: ${update.delayReason}`;
  //     }

  //     if (update.updatedProgress) {
  //       if (updateString.length > 0) updateString += ", ";
  //       updateString += `COMPLETION % ${update.progress} → ${update.updatedProgress}`;
  //     }

  //     let log = {
  //       date: formatDate(update.createdAt),
  //       stakeholder: stakeholderArray.find((stakeholder) => stakeholder.code === update.code).name,
  //       activity: update.name,
  //       update: updateString,
  //     };
  //     tempLogs.push(log);
  //   }
  //   tempLogs.sort((a, b) => new Date(b.date) - new Date(a.date));
  //   setActivityLogs(tempLogs);
  // };

  const getAllTasks = async () => {
    try {
      let res = await TaskService.getAllPlannerTasks(user.id);
      getProgressChartData(res.data);
      // getDelayChartData(res.data);
      await getAllInvites();
    } catch (error) {
      setLoading(false);
      console.log("🚀 ~ getAllTasks ~ error:", error);
    }
  };

  const getAllInvites = async () => {
    try {
      let res = await InviteService.getAcceptedAll(user.project.id);

      let missingStakeholders = 0;
      for (let invite of res.data) {
        let updateCount = 0;
        for (let task of invite.tasks) {
          updateCount += task.updates.length;
        }
        if (updateCount === 0) {
          missingStakeholders += 1;
        }
      }
      calcImpact(res.data);
      setUpdatePanelData({ missingStakeholders, ...calcImpact(res.data) });
    } catch (error) {
      console.log("🚀 ~ getAllInivtes ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  // const delayBarChat = (updatesData) => {
  //   let updates = [...updatesData];
  //   //filter updates according to selected stakeholder
  //   if (selectedStakeholder.code !== "All") {
  //     updates = updatesData.filter((update) => update.code === selectedStakeholder.code);
  //   }
  //   // Extract the min and max dates
  //   const minMaxDates = updates.reduce(
  //     (acc, curr) => {
  //       const currDate = curr.actionDate;
  //       if (!acc.min || currDate < acc.min) acc.min = currDate;
  //       if (!acc.max || currDate > acc.max) acc.max = currDate;
  //       return acc;
  //     },
  //     { min: null, max: null }
  //   );

  //   //get labels like 04/2024, 05/2024, .... 10/2024
  //   const labels = [];
  //   const start = moment(minMaxDates.min);
  //   const end = moment(minMaxDates.max);
  //   while (start.isSameOrBefore(end, "month")) {
  //     labels.push(start.format("MM/YYYY"));
  //     start.add(1, "month");
  //   }

  //   let data = [];
  //   for (let label of labels) {
  //     let totalImpact = 0;
  //     for (let update of updates) {
  //       if (moment(update.actionDate).isSame(moment(label, "MM/YYYY"), "month")) {
  //         totalImpact += update.impact;
  //       }
  //     }
  //     data.push(totalImpact);
  //   }

  //   setBarData({ labels, data });
  // };

  const getProgressChartData = (tasks) => {
    let notStartedCount = 0,
      inProgressCount = 0,
      completedCount = 0;
    for (let task of tasks) {
      if (task.PercentComplete === 0) {
        notStartedCount++;
      } else if (task.PercentComplete > 0 && task.PercentComplete < 100) {
        inProgressCount++;
      } else if (task.PercentComplete === 100) {
        completedCount++;
      }
    }
    let total = notStartedCount + inProgressCount + completedCount;
    setProgressChartData({
      notStarted: (notStartedCount / total) * 100,
      inProgress: (inProgressCount / total) * 100,
      completed: (completedCount / total) * 100,
    });
  };

  // const getDelayChartData = (tasks) => {
  // for (let task of tasks) {
  //   console.log("🚀 ~ getDelayChartData ~ task:", task);
  // }
  // };

  const calcImpact = (invites) => {
    let tImpact = 0;
    let tActivities = 0;
    for (let invite of invites) {
      tImpact += invite.impact;
      tActivities += invite.updateCount;
    }
    return { totalImpact: tImpact, updatedActivityCount: tActivities };
  };

  const getAllStakeholders = async () => {
    try {
      let response = await InviteService.getAcceptedAll(user.project.id);
      let temp = [{ name: "All stakeholders", code: "All", userId: -1 }];
      for (let invite of response.data) {
        temp.push({ code: invite.code, name: invite.invited.stakeholder.name, userId: invite.invited.id });
      }
      // setSelectedStakeholder(temp[0]);
      setStakeholderArray(temp);
    } catch (err) {
      console.log("Fetching invites error: ", err);
    }
  };

  return (
    <div className="w-full h-full">
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <PuffLoader
            color="#007DE5"
            loading={loading}
            cssOverride={override}
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div className="w-full px-1">
          <div className="w-full">{updatePanelData && <UpdatesPanel data={updatePanelData} />}</div>
          <div className="w-full mt-7">
            <ActivityStatus data={progressChartData} />
          </div>
          <div className="w-full mt-4 flex items-center gap-5">
            <div className="w-[50%] h-full">
              <DelayByCycle stakeholderArray={stakeholderArray} />
            </div>
            <div className="w-[50%] h-full pt-7">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <BarChart
                  data={{
                    labels: ["Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
                    data: [40, 40, 38, 37, 37, 25, 24, 20, 20, 18, -5, -14],
                  }}
                  title="Float Trend"
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <ActionHistoryTable packageArray={stakeholderArray} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Overview;
