import React, { useEffect } from "react";
import { CursorArrowRaysIcon, EnvelopeOpenIcon, UsersIcon, BuildingOfficeIcon } from "@heroicons/react/24/outline";

const UpdatesPanel = ({ data }) => {
  return (
    data && (
      <div>
        <h3 className="text-base font-semibold leading-6 text-gray-900">Overview Dashboard</h3>
        <dl className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 ">
          <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6">
            <dt>
              <div className="absolute rounded-md bg-indigo-500 p-3">
                <UsersIcon aria-hidden="true" className="h-6 w-6 text-white" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">Activities Updated</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{data.updatedActivityCount}</p>
            </dd>
          </div>

          <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6">
            <dt>
              <div className="absolute rounded-md bg-indigo-500 p-3">
                <EnvelopeOpenIcon aria-hidden="true" className="h-6 w-6 text-white" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">Missing Stakeholders</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{data.missingStakeholders}</p>
            </dd>
          </div>

          <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6">
            <dt>
              <div className="absolute rounded-md bg-indigo-500 p-3">
                <CursorArrowRaysIcon aria-hidden="true" className="h-6 w-6 text-white" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">Total Float Impact</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{data.totalImpact}</p>
            </dd>
          </div>

          <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6">
            <dt>
              <div className="absolute rounded-md bg-indigo-500 p-3">
                <BuildingOfficeIcon aria-hidden="true" className="h-6 w-6 text-white" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">Update Cycle Completion</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">43%</p>
            </dd>
          </div>
        </dl>
      </div>

      // <div className="w-full h-full flex flex-col gap-5">
      //   <div className="w-full h-[54px] bg-borderColor flex items-center justify-between gap-4">
      //     <div className="w-full h-full bg-[#E5E7F4] flex items-center justify-center rounded-md shadow-md">
      //       <div className="flex items-center ">
      //         <span className="font-bold text-[31px] font-black ">{data.updatedActivityCount}&nbsp;</span>
      //         <span className="font-normal 2xl:text-[16px] text-[12px] font-black mt-1">
      //           ACTIVITIES UPDATED
      //         </span>
      //       </div>
      //     </div>
      //     <div className="w-full h-full bg-[#FEE2E2] flex items-center justify-center rounded-md shadow-md">
      //       <div className="flex items-center ">
      //         <span className="font-bold text-[31px] text-[#EF4444] ">{data.totalImpact}&nbsp;</span>
      //         <span className="font-normal 2xl:text-[16px] text-[11px] font-black mt-1">
      //           TOTAL IMPACT (DAYS)
      //         </span>
      //       </div>
      //     </div>
      //   </div>
      //   <div className="w-full flex-auto bg-white rounded-md shadow-md">
      //     <table className="w-full rounded-md">
      //       <thead>
      //         <tr className=" border-b-[2px] border-borderColor ">
      //           <th className="w-[30%]">
      //             <div className="font-normal text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
      //               <span>PACKAGE</span>
      //             </div>
      //           </th>
      //           <th className="w-[30%]">
      //             <div className="font-normal text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
      //               <span>STAKEHOLDER</span>
      //             </div>
      //           </th>
      //           <th className="w-[40%]">
      //             <div className="font-normal text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
      //               <span>DELAYED FINISH DATES</span>
      //             </div>
      //           </th>
      //         </tr>
      //       </thead>
      //       <tbody>
      //         {data.invites &&
      //           data.invites.map((invite, index) => (
      //             <tr key={index} className="h-[80px] w-full">
      //               <td>
      //                 <div className="w-full flex items-center justify-center">
      //                   <span className="font-normal text-[14px] text-[#333333]">{invite.code}</span>
      //                 </div>
      //               </td>
      //               <td>
      //                 <div className="w-full flex items-center justify-center">
      //                   <span className="font-normal text-[14px] text-[#333333]">
      //                     {invite.invited.stakeholder.name}
      //                   </span>
      //                 </div>
      //               </td>
      //               <td>
      //                 <div className="w-full flex items-center justify-center">
      //                   <span className="font-normal text-[14px] text-[#333333]">
      //                     {invite.delayedEndDateUpdateCount > 0 && invite.delayedEndDateUpdateCount}
      //                   </span>
      //                 </div>
      //               </td>
      //             </tr>
      //           ))}
      //       </tbody>
      //     </table>
      //   </div>
      //  </div>
    )
  );
};

export default UpdatesPanel;
