import { current } from "@reduxjs/toolkit";
import api from "./api";
import AuthService from "./auth.service";

const create = (data) => {
  return api.post("/api/update/create", data);
};

const reject = (data) => {
  return api.post("/api/update/reject", data);
};

const accept = (data) => {
  return api.post("/api/update/accept", data);
};

const bulkCreate = (data) => {
  return api.post("/api/update/bulk-create", data);
};

const calcImpact = (userId, data) => {
  return api.post("/api/update/calc-impact", { userId, data });
};

const getAcceptedAll = (projectId) => {
  return api.get("/api/update/get-accepted-all", { params: { projectId } });
};

const getActivityLogsForTask = (taskId) => {
  return api.get("/api/update/get-activity-logs-for-task", { params: { taskId } });
};
const getActivityLogsForPackage = (code, projectId) => {
  return api.get("/api/update/get-activity-logs-for-package", { params: { code, projectId } });
};

const getActivityLogsForStakeholder = (code, projectId) => {
  return api.get("/api/update/get-activity-logs-for-stakeholder", { params: { code, projectId } });
};

const UpdateService = {
  create,
  accept,
  reject,
  bulkCreate,
  calcImpact,
  getAcceptedAll,
  getActivityLogsForTask,
  getActivityLogsForPackage,
  getActivityLogsForStakeholder,
};

export default UpdateService;
