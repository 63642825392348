import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import BlueButton from "src/components/buttons/BlueButton";
import EmailInput from "src/components/inputs/EmailInput";
import PasswordInput from "src/components/inputs/PasswordInput";
import { getImageUrl } from "src/utils/common";
import AuthService from "src/services/auth.service";
import TokenService from "src/services/token.service";
import UserService from "src/services/user.service";
import { setAuthState } from "src/redux/slices/AuthSlice";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submit = () => {
    AuthService.login(email, password)
      .then(function (response) {
        console.log("YYYYYYYYYY: ", response.data);
        if (response.status == 200) {
          // toast("Log in success");
          TokenService.updateLocalAccessToken(response.data.accessToken);
          UserService.saveCurrentUser(response.data.user);
          dispatch(setAuthState(true));
          if (password === "123456") {
            navigate("/auth/reset-password");
          } else {
            if (response.data.user.role.name === "Planner") {
              if (response.data.user.planner && response.data.user.planner.sharepointAccessToken) {
                navigate("/planner/updater/overview");
              } else {
                navigate("/auth/connect-msproject");
              }
            } else if (response.data.user.role.name === "Stakeholder") {
              if (response.data.user.stakeholder) {
                navigate("/stakeholder/updates");
              } else {
                navigate("/auth/create-account");
              }
            }
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          toast(error.response.data.message);
        } else {
          toast("An error occured");
        }
      });
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center relative ">
      <img src={getImageUrl("/images/auth/bg.svg")} className="w-screen h-screen absolute left-0 top-0 object-cover" />

      <div className="bg-[#E5E7F488] w-[332px] rounded-md z-10 px-[16px] py-[32px] flex items-center flex-col">
        <img src={getImageUrl("/images/common/logo.png")} className="w-[34px] h-[34px]" />
        <span className="text-[#333333] text-[24px] font-bold mt-[8px] ">Welcome to Instaplan</span>
        <p className="w-full text-[#333333] text-[12px] font-bold mt-[40px]">EMAIL</p>
        <EmailInput onEmailChanged={(value) => setEmail(value)} />

        <p className="w-full text-[#333333] text-[12px] font-bold mt-[16px] ">PASSWORD</p>
        <PasswordInput onPasswordChanged={(value) => setPassword(value)} />

        <div className="w-full mt-[40px] mb-[8px]">
          <BlueButton title="LOGIN" onClick={submit} />
        </div>
      </div>
    </div>
  );
};

export default Login;
