import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiFillMessage } from "react-icons/ai";

import UserService from "src/services/user.service";
import TaskService from "src/services/task.service";
import PuffLoader from "react-spinners/PuffLoader";
import BlueButton from "src/components/buttons/BlueButton";
import TableFooter from "src/components/TableFooter";
import UpdateModal from "./UpdateModal";
import moment from "moment";
import { formatDate, toLocalDate } from "src/utils/common";
import Accepted from "src/components/status/Accepted";
import Rejected from "src/components/status/Rejected";
import Pending from "src/components/status/Pending";
import UpdateService from "src/services/update.service";
import { toast } from "react-toastify";
import ConfirmationModal from "./ConfirmationModal";
import ActivityLogs from "src/components/ActivityLogs";
import MessageService from "src/services/message.service";
import MessageModal from "src/pages/planner/updater/updates/components/MessageModal";
import WarningButton from "src/components/buttons/WarningButton";
import Delaycategory from "src/services/delaycategory.service";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#007DE5",
};

const Update = () => {
  const navigate = useNavigate();
  const user = UserService.getCurrentUser();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [impactLoading, setImpactLoading] = useState(false);
  const [updateModalShown, setUpdateModalShown] = useState(false);
  const [confirmationModalShown, setConfirmationModalShown] = useState(false);
  const [task, setTask] = useState(null);
  const [updates, setUpdates] = useState([]);
  const [impact, setImpact] = useState(0);
  const [impactArray, setImpactArray] = useState([]);
  //activity logs
  const [activityLogs, setActivityLogs] = useState([]);
  const [activityLogModalShown, setActivityLogModalShown] = useState(false);
  const [taskForLog, setTaskForLog] = useState(null);
  //Message Modal
  const [messageModalShown, setMessageModalShown] = useState(false);
  const [messageTask, setMessageTask] = useState(null);
  const [categoryObjects, setCategoryObjects] = useState([]);

  useEffect(() => {
    getAllTasks();

    Delaycategory.getAll()
      .then((response) => {
        setCategoryObjects(response.data);
      })
      .catch((error) => {
        console.log("🚀 ~Delaycategory  getAll ~ error:", error);
      });
  }, []);

  const getAllTasks = async () => {
    setLoading(true);
    try {
      let res = await TaskService.getAllStakeholderTasks(user.id);
      console.log("🚀 ~ getAllTasks ~ res:", res);
      setTasks(res.data);
    } catch (error) {
      console.log("🚀 ~ getAllTasks ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  const save = (updatedData) => {
    let oldUpdate = updates.find((item) => item.task === updatedData.task);
    console.log("🚀 ~ save ~ updatedData:", updatedData, "Oldupdate: ", oldUpdate);
    if (oldUpdate) {
      let index = updates.findIndex((item) => item.task === updatedData.task);
      let newUpdates = [...updates];
      newUpdates[index] = updatedData;
      setUpdates(newUpdates);
    } else {
      let newUpdates = [...updates, updatedData];
      setUpdates(newUpdates);
    }
    setUpdateModalShown(false);
    toast("Activity saved", { type: "success" });

    // let index = tasks.findIndex((item) => item.id === updatedData.task);
    // let temp = [...tasks];
    // if (updatedData.updatedStartDate) temp[index].updatedStartDate = new Date(updatedData.updatedStartDate);
    // if (updatedData.updatedEndDate) temp[index].updatedEndDate = new Date(updatedData.updatedEndDate);
    // if (updatedData.updatedProgress) temp[index].updatedProgress = updatedData.updatedProgress;

    // setTasks(temp);
  };

  const submit = async () => {
    if (updates.length === 0) {
      toast("Please add an update", { type: "error" });
      return;
    }
    setImpactLoading(true);
    try {
      let res = await UpdateService.calcImpact(user.id, updates);
      setImpactArray(res.data);
      console.log("🚀 ~ submit ~ res.data:", res.data);
      let totalImpact = Math.max(...res.data);
      setImpact(totalImpact >= 0 ? totalImpact : 0);
      setConfirmationModalShown(true);
    } catch (error) {
      console.log("🚀 ~ save ~ error:", error);
    } finally {
      setImpactLoading(false);
    }
  };

  const handleSubmit = async () => {
    setConfirmationModalShown(false);
    try {
      //add impact to updates
      let temp = [...updates];
      for (let i = 0; i < temp.length; i++) {
        temp[i].impact = impactArray[i];
      }
      setUpdates(temp);
      console.log("🚀 ~ handleSubmit ~ updates:", temp);
      await UpdateService.bulkCreate(updates);
      toast("Activity submitted", { type: "success" });
      getAllTasks();
      setUpdates([]);
    } catch (error) {
      console.log("🚀 ~ save ~ error:", error);
    }
  };

  const handleStartDateConfirmation = (task, index, checked) => {
    TaskService.update({ id: task.id, startDateConfirmed: checked })
      .then((response) => {
        let temp = [...tasks];
        temp[index].startDateConfirmed = checked;
        setTasks(temp);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEndDateConfirmation = (task, index, checked) => {
    TaskService.update({ id: task.id, endDateConfirmed: checked })
      .then((response) => {
        let temp = [...tasks];
        temp[index].endDateConfirmed = checked;
        setTasks(temp);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getActivityLogs = async (taskId) => {
    try {
      let response = await UpdateService.getActivityLogsForTask(taskId);
      setActivityLogs(response.data.filter((item) => moment(item.date).isSame(moment(new Date()), "month")));
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessage = async (message) => {
    let data = { message, sender: user.id, task: taskForLog.id };
    try {
      await MessageService.create(data);
      getActivityLogs(taskForLog.id);
    } catch (error) {
      console.log("Sending message error: ", error);
    }
  };

  const removeUpdate = (task) => {
    let index = updates.findIndex((item) => item.task === task.id);
    let newUpdates = [...updates];
    newUpdates.splice(index, 1);
    setUpdates(newUpdates);
  };

  return (
    <div className="w-full h-full bg-[#F5F5FB] flex flex-col ">
      <UpdateModal
        shown={updateModalShown}
        onCancel={() => {
          setUpdateModalShown(false);
          // getAllTasks();
        }}
        task={task}
        save={save}
        removeUpdate={removeUpdate}
      />
      <ConfirmationModal
        shown={confirmationModalShown}
        onCancel={() => {
          setConfirmationModalShown(false);
        }}
        impact={impact}
        submit={handleSubmit}
      />
      <ActivityLogs
        send={sendMessage}
        logs={activityLogs}
        isForPlanner={false}
        shown={activityLogModalShown}
        onCancel={() => setActivityLogModalShown(false)}
      />
      <MessageModal shown={messageModalShown} task={messageTask} onCancel={() => setMessageModalShown(false)} />
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <PuffLoader
            color="#007DE5"
            loading={loading}
            cssOverride={override}
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <span className="font-medium text-[24px] font-black">Square Phillips 1 Residential Building</span>
          <div className="w-full mt-3 bg-[#fafafd] shadow-lg p-[16px] flex flex-col rounded-lg">
            <div className="w-full flex items-center justify-between py-1">
              <p className="font-semibold text-[20px] ">Update List</p>
              <div className="w-[120px]">
                <BlueButton title="SUBMIT" onClick={submit} loading={impactLoading} />
              </div>
            </div>
            <div className=" rounded-[4px] px-1 ">
              <div className="mt-2 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                      <table className="w-full ">
                        <thead>
                          <tr className=" border-b-[2px] border-borderColor ">
                            <th className="w-[70px]">
                              <div className="font-semibold text-[12px] flex items-center px-2 h-[40px] text-[#606060]">
                                <span>#</span>
                              </div>
                            </th>

                            <th className="w-[25%]">
                              <div className="font-semibold text-[12px] flex items-center h-[40px] text-[#606060]">
                                <span>TASK NAME</span>
                              </div>
                            </th>

                            <th className="w-[20%]">
                              <div className="font-semibold text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                                <span>PLANNED DATE</span>
                              </div>
                            </th>

                            <th className="w-[10%]">
                              <div className="font-semibold text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                                <span>ACTUAL</span>
                              </div>
                            </th>

                            <th className="w-[10%]">
                              <div className="font-semibold text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                                <span>PROGRESS %</span>
                              </div>
                            </th>

                            <th className="min-w-[100px]">
                              <div className="font-semibold text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                                <span>PACKAGE</span>
                              </div>
                            </th>

                            <th className="min-w-[100px]">
                              <div className="font-semibold text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                                <span>REVIEW STATUS</span>
                              </div>
                            </th>

                            <th className="w-[100px]">
                              <div className="font-semibold text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                                <span>ACTIONS </span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tasks.map((item, index) => (
                            <tr key={index} className="h-[80px] w-full">
                              <td className="px-2">
                                <span className="font-normal text-[14px] text-[#333333]">{index + 1}</span>
                              </td>
                              <td>
                                <span className="font-normal text-[14px] text-[#333333]">{item.name}</span>
                              </td>

                              <td>
                                <div className="w-full h-[60px] flex items-center justify-center ">
                                  <div className=" h-full flex flex-col ">
                                    <div className="w-full h-[50%] flex items-center ">
                                      <span className="font-normal text-[13px] text-[#8D8D8D] w-[50px]">Start</span>
                                      <p className="font-normal text-[13px] text-[#333333]">
                                        {formatDate(item.startDate)}
                                        {updates.find((update) => update.task === item.id) &&
                                          updates.find((update) => update.task === item.id).updatedStartDate && (
                                            <span className="font-normal text-[13px] text-[#333333]">
                                              &nbsp;→&nbsp;
                                              {formatDate(
                                                updates.find((update) => update.task === item.id).updatedStartDate
                                              )}
                                            </span>
                                          )}
                                      </p>
                                    </div>
                                    <div className="w-full h-[50%] flex items-center relative">
                                      <span className="font-normal text-[13px] text-[#8D8D8D] w-[50px]">Finish</span>
                                      <p className="font-normal text-[13px] text-[#333333]">
                                        {formatDate(item.endDate)}
                                        {updates.find((update) => update.task === item.id) &&
                                          updates.find((update) => update.task === item.id).updatedEndDate && (
                                            <span className="font-normal text-[13px] text-[#333333]">
                                              &nbsp;→&nbsp;
                                              {formatDate(
                                                updates.find((update) => update.task === item.id).updatedEndDate
                                              )}
                                            </span>
                                          )}
                                      </p>
                                      <div className="absolute bottom-[0px] right-[-30px] bg-blue-400">
                                        {updates.find((update) => update.task === item.id) &&
                                          updates.find((update) => update.task === item.id).delayCategory && (
                                            <WarningButton
                                              onClick={() => {
                                                setMessageModalShown(true);
                                                let update = updates.find((update) => update.task === item.id);
                                                update = {
                                                  ...update,
                                                  delayCategory: categoryObjects.find(
                                                    (category) => category.id === update.delayCategory
                                                  ),
                                                };
                                                let task = {
                                                  ...item,
                                                  updates: [update],
                                                };
                                                setMessageTask(task);
                                              }}
                                            />
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className="w-full h-full">
                                  <div className="w-full flex flex-col items-center justify-center gap-5">
                                    <div className="flex relative items-center">
                                      <input
                                        type="checkbox"
                                        checked={item.startDateConfirmed}
                                        onChange={(e) => handleStartDateConfirmation(item, index, e.target.checked)}
                                      />

                                      {moment(toLocalDate(item.startDate)).isBefore(moment(new Date()), "day") &&
                                        !item.startDateConfirmed && (
                                          <span className="font-medium text-[11px] text-[#f54949] absolute left-[20px]">
                                            PASSED
                                          </span>
                                        )}
                                    </div>

                                    <div className="flex relative">
                                      <input
                                        type="checkbox"
                                        checked={item.endDateConfirmed}
                                        onChange={(e) => handleEndDateConfirmation(item, index, e.target.checked)}
                                      />
                                      {moment(toLocalDate(item.endDate)).isBefore(moment(new Date()), "day") &&
                                        !item.endDateConfirmed && (
                                          <span className="font-medium text-[11px] text-[#f54949] absolute left-[20px]">
                                            PASSED
                                          </span>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td>
                                {item.updatedProgress && item.progress !== item.updatedProgress ? (
                                  <div className="flex items-center justify-center">
                                    <span className="font-normal text-[14px] text-[#333333]">
                                      {`${item.progress} → ${item.updatedProgress}`}
                                    </span>
                                  </div>
                                ) : (
                                  <div className="flex items-center justify-center">
                                    <span className="font-normal text-[14px] text-[#333333]">{item.progress}</span>
                                  </div>
                                )}
                              </td>

                              <td>
                                <div className="flex items-center justify-center">
                                  <span className="font-normal text-[14px] text-[#333333]">{item.code}</span>
                                </div>
                              </td>
                              <td>
                                <div className="flex items-center justify-center">
                                  <div className="relative flex items-center">
                                    <div>
                                      {item.updates.length > 0 && (
                                        <>
                                          {item.status === 0 ? (
                                            <Pending />
                                          ) : item.status === 1 ? (
                                            <Accepted />
                                          ) : item.status === 2 ? (
                                            <Rejected />
                                          ) : null}
                                        </>
                                      )}
                                    </div>
                                    <div
                                      onClick={() => {
                                        setActivityLogModalShown(true);
                                        setTaskForLog(item);
                                        getActivityLogs(item.id);
                                      }}
                                      className="cursor-pointer"
                                    >
                                      <AiFillMessage size={20} />
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="w-full h-full flex items-center justify-center px-1">
                                  <div className="w-[100px]">
                                    <BlueButton
                                      title="Edit"
                                      onClick={() => {
                                        setUpdateModalShown(true);
                                        setTask(item);
                                      }}
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* <TableFooter pageNumber={1} totalNumber={100} /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Update;
